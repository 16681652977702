import { gql } from "@apollo/client"

const GET_PROJECT_DETAILS = gql`
  query GetProjectsDetails($culture: String, $array: [Int]) {
    projects: contentDescendantsByAbsoluteRoute(
      route: "/he-il/shared/general-information/projects"
      culture: $culture
      first: 1000
      where: { and: [{ contentType: { alias: { in: ["project"] } } }, { id: { in: $array } }] }
    ) {
      nodes {
        id
        url
        contentType {
          alias
        }
        namedProperties {
          ... on IProject {
            moreInfo {
              value
            }
            projectParent {
              contentList {
                id
              }
            }
            thumbnailImageForLobbyPage {
              mediaItems {
                url
              }
            }
            heroBanner {
              blocks {
                ... on BasicBlockListItem {
                  contentProperties {
                    value {
                      ... on MintMediaPicker {
                        mediaItems {
                          id
                          url
                          altText
                        }
                      }
                    }
                  }
                }
              }
            }
            projectTitle {
              value
            }
            projectStatus {
              contentList {
                id
              }
            }
            propertyDesignations {
              contentList {
                id
              }
            }
            projectType {
              value
            }
            city {
              contentList {
                id
              }
            }
            country {
              contentList {
                id
              }
            }
            groupMembers {
              contentList {
                id
              }
            }
            groupSIte {
              contentList {
                id
              }
            }
            leadFormBMBYId {
              value
            }
            emailForLead {
              value
            }
          }
        }
      }
    }
    filters: contentDescendantsByAbsoluteRoute(
      route: "/he-il/shared/general-information"
      culture: $culture
      first: 1000
      where: {
        contentType: {
          alias: {
            in: [
              "projectStatus"
              "propertyDesignation"
              "groupSite"
              "groupPartner12"
              "groupPartner1"
              "propertyType"
              "groupPartner"
              "groupMemmber"
              "groupEntrepreneur"
            ]
          }
        }
      }
    ) {
      nodes {
        id
        url
        contentType {
          alias
        }
        namedProperties {
          ... on IPropertyDesignation {
            Designation {
              value
            }
          }
          ... on IGroupSite {
            siteName {
              value
            }
          }
          ... on IProjectStatus {
            statusLisi {
              value
            }
          }
          ... on IGroupMemmber {
            memberName {
              value
            }
            lOGO {
              mediaItems {
                url
              }
            }
            url {
              links {
                url
              }
            }
          }
          ... on IGroupEntrepreneur {
            entrepreneurName {
              value
            }
            url {
              links {
                url
              }
            }
            lOGO {
              mediaItems {
                url
              }
            }
          }
          ... on IGroupPartner {
            partnerName {
              value
            }
          }
          ... on IGroupPartner1 {
            partnerName {
              value
            }
          }
          ... on IGroupPartner12 {
            partnerName {
              value
            }
          }
          ... on IPropertyType {
            propertyTypeName {
              value
            }
          }
        }
      }
    }
  }
`

export default GET_PROJECT_DETAILS
