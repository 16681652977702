"use client"

import styled from "@emotion/styled"
import { useRef, useState } from "react"
import { Groups, MatchedStatuses, ProjectPage, useOutsideClick } from "utils"
import { RelatedProjects } from "utils/utils"
import ContactContent from "../Contact/ContactContent"
import ContactForm from "./Form"
import Description from "./Marketing/Description"
import Gallery from "./Marketing/Gallery"
import TopHeader from "./Marketing/TopHeader"
import Partners from "./Partners"
import { ProjectsMessages } from "./Projects"
import StickyCTA from "./StickyCTA"

export const Backdrop = styled.div<{ z?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); // Semi-transparent black
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.z ? props.z : 100)};
`

type Props = {
  project: ProjectPage
  relatedProjects?: RelatedProjects
  statuses: MatchedStatuses
  groups: Groups
  formSubjects: Record<string, string>
  designations: string[]
  cities: string[]
  countries: string[]
  locale: string
  messages: ProjectsMessages
  propertyTypes: { id: number; value: string }[]
  toLobby?: string
}

export const StickyCTAWrap = styled.div<{ z: number }>`
  position: fixed;
  width: fit-content;
  bottom: 16px;
  z-index: 100;
  right: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    right: 120px;
    bottom: 24px;
  }

  z-index: ${(props) => props.z};
`

export const StickyFormWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  padding-top: 40px;
  z-index: 10000;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    max-height: calc(100% - 48px);
  }
`

function StandardProject({
  locale,
  project,
  statuses,
  groups,
  formSubjects,
  designations,
  cities,
  countries,
  messages,
  toLobby,
}: Props) {
  const [isBackdropVisible, setIsBackdropVisible] = useState(false)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const stickyRef = useRef(null)
  const formRef = useRef(null)
  const handleBackdropClose = () => {
    setIsFormVisible(false)
    setIsBackdropVisible(false)
    setIsDropDownOpen(false)
  }
  useOutsideClick([stickyRef, formRef], handleBackdropClose)

  return (
    //   <Container>
    //   <CustomBreadcrumbs
    //     current={{ title: `${project.projectTitle}`, url: `/` }}
    //     parents={[{ title: locale === "he" ? "ראשי" : "Homepage", url: "/" }]}
    //   />
    // </Container>
    <div>
      <TopHeader
        projectData={project}
        locale={locale}
        city={cities[0]}
        country={countries[0]}
        designations={designations}
        statuses={statuses}
        contactAnchor={project.contactAnchor}
      />
      {isBackdropVisible && <Backdrop />}
      {!project.hideLeadForm && (
        <StickyCTAWrap ref={stickyRef} z={isFormVisible ? 90 : 100}>
          <StickyCTA
            projectType={project.projectType}
            messages={messages}
            setIsBackdropVisible={setIsBackdropVisible}
            open={isDropDownOpen}
            setIsFormVisible={setIsFormVisible}
            setIsDropDownOpen={setIsDropDownOpen}
            phone={project.phone}
            miniSite={project.miniSite}
            virtualTourLink={project.virtualTourLink}
          />
        </StickyCTAWrap>
      )}
      {isFormVisible ? (
        <StickyFormWrap ref={formRef}>
          <ContactForm
            messages={messages}
            formSubjects={formSubjects}
            onClose={handleBackdropClose}
            status={project.projectStatuses[0]?.name}
            bmbyId={project.leadFormBMBYId}
            companyId={project.companyId}
            emailForLead={project.emailForLead}
          />
        </StickyFormWrap>
      ) : null}
      <Description projectData={project} locale={locale} toLobby={toLobby} />
      <Gallery gallery={project.gallery} locale={locale} />
      {project.officeInfo?.[0] ? (
        <ContactContent data={project.officeInfo?.[0]} project={true} />
      ) : null}
      <Partners
        messages={{
          groups: messages.groups,
          collaborators: project.groupPartnersTitle || "",
          entr: project.entrepreneursTitle || "efasfs",
        }}
        groups={groups}
        companyText={project.companyText}
        subTitle={project.subTitle2}
      />
    </div>
  )
}

export default StandardProject
