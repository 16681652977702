import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { useLocale, useTranslations } from "next-intl"
import Image from "next/image"
import { useRef, useState } from "react"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"

import Link from "next/link"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container, Desktop, GalleryIcon, LeftArrow, NewText, RightArrow, XIcon } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment, getUmbracoMediaUrl } from "utils"

const StyledGalleryDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  img {
    border-radius: 16px;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
  }
`

const StyledRightDiv = styled.div`
  height: 222px;
  position: relative;
`

const StyledLeftDiv = styled.div`
  height: 222px;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`

const SytledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const StyledIconsSwapper = styled.div<{
  top?: string
  right?: string
  left?: string
  mobileLeft?: string
  mobileRight?: string
  mobileTop?: string
}>`
  position: absolute;
  top: ${(props) => props.top || "16px"};
  left: ${(props) => props.left || "16px"};
  right: ${(props) => props.right || "unset"};
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: fit-content;
  ${(props) => props.theme.breakpoints.down("md")} {
    right: ${(props) => props.mobileRight || "unset"};
    top: ${(props) => props.mobileTop || "16px"};
    left: ${(props) => props.mobileLeft || "16px"};
  }
`
export const StyledExpandIcon = styled.div<{
  customTooltip?: boolean
  direction?: string
  locale?: string
}>`
  width: fit-content;
  height: fit-content;
  background-color: white;
  border-radius: 123.08px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  overflow: hidden;
  transition: width 330ms ease-in-out, gap 330ms ease-in-out;

  & .MuiIconButton-root {
    position: relative;
    z-index: 9;
    background-color: inherit;
    transition: padding 330ms ease-in-out;
    width: 32px;
    height: 32px;
  }

  p {
    opacity: 0;
    transform: ${(props) =>
        (props.locale === "en" && props.direction !== "row-reverse") ||
        (props.locale === "he" && props.direction === "row-reverse")
          ? `translateX(calc(-100% - 4.92px))`
          : `translateX(calc(100% + 4.92px))`}
      scaleX(0);
    transition: transform 330ms ease-in-out, opacity 330ms ease-in-out, width 330ms ease-in-out;
    width: 0;
    white-space: nowrap;
  }

  ${(props) =>
    props.customTooltip &&
    `
        &:hover {
          width: unset ;
          
          transition: width 330ms ease-in-out, gap 330ms ease-in-out;
          
          p {
        opacity: 1;
        transform: translateX(0) scaleX(1);
        width: fit-content;
        transition: transform 330ms ease-in-out, opacity 330ms ease-in-out, width 330ms ease-in-out;
            ${
              props.direction === "row-reverse"
                ? "padding-inline-start: 8px"
                : "padding-inline-end: 8px"
            };
      }

     

      & .MuiIconButton-root:hover {
        background-color: inherit;
      }
    }
  `};
`

// export const StyledExpandIcon = styled.div<{ customTooltip?: boolean; direction?: string }>`
//   width: 32px;
//   height: 32px;
//   background-color: white;
//   border-radius: 50%;
//   box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
//   display: flex;
//   flex-direction: ${(props) => props.direction || "row"};
//   align-items: center;
//   justify-content: center;
//   padding: 8px;
//   cursor: pointer;
//   overflow: hidden;
//   transition: width 330ms ease-in-out;
//   & .MuiIconButton-root {
//     position: relative;
//     z-index: 9;
//     background-color: inherit;
//   }
//   p {
//     opacity: 0;
//     transform: translateX(-100%) scaleX(0);
//     transition: transform 330ms ease-in-out;
//     width: 0;
//   }
//   ${(props) => {
//     if (props.customTooltip)
//       return `&:hover {
//           width: unset;
//           border-radius: 123.08px;
//           gap: 4.92px;
//           transition: width 330ms ease-in-out;
//           p {
//             opacity: 1;
//             transform: translateX(0);
//             width: fit-content;
//             transition: transform 330ms ease-in-out;
//           };
//           & .MuiIconButton-root {
//             padding: 0;
//           }
//           & .MuiIconButton-root:hover {
//             background-color: inherit;
//           }
//       };
//       `
//   }};
// `

const StyledVirtualDiv = styled.div`
  box-shadow: 0px 4px 8px 0px #00000029;
  position: absolute;
  bottom: 16px;
  left: 16px;
  background: #0000007a;
  padding: 16px;
  height: 39px;
  border-radius: 200px;
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
  }
`

export const StyledGalleryPopup = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 10000;
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 98px;
  }
`

export const StyledCloseDiv = styled.div`
  position: absolute;
  top: 32px;
  right: 16px;
  button {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: white;
    }
  }
  svg {
    path {
      stroke: black;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 32px;
    right: 0px;
  }
`

export const StyledContainer = styled(Container)`
  position: relative;
  height: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    img {
      border-radius: 16px;
    }
  }
`

export const StyledImagesDiv = styled.div`
  height: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const StyledMainImage = styled.div<{ heightMargin: number }>`
  position: relative;
  margin-top: 40px;
  height: 265px;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: calc(100% - 100px);
    height: calc(100% - ${(props) => props.heightMargin}px);
  }
`

const StyledThumbImages = styled.div`
  width: 100%;
  padding-top: 16px;
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: calc(100% - 100px);
  }
`

const StyledSlideDiv = styled.div<{ $active: boolean }>`
  height: 108px;
  position: relative;

  border: ${(p) => (p.$active ? "2px solid white" : "none")};

  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 200px;
  }
`
const StyledIconButton = styled(IconButton)`
  background-color: white !important;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 40px !important;
    height: 40px !important;
  }
  &:disabled {
    background-color: gray !important;
  }
  &:hover {
    background-color: white !important;
  }
`

const StyledMain = styled.div``

type Props = {
  apartment: Apartment
  imageGallery?: {
    apartmentThumbnail?: string
    apartmentGallery?: string[]
    numberOfRooms?: number
  }
}
function ApartmentGallery({ apartment, imageGallery }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef<any>(null)
  const locale = useLocale()
  const t = useTranslations("ProjectsPage")
  const { gallery } = apartment
  const updateSwiperSlide = (index: number) => {
    setActiveIndex(index)
    if (swiperRef.current) {
      swiperRef.current.slideTo(index)
    }
  }

  if (
    gallery.length === 0 &&
    (!imageGallery?.apartmentGallery || imageGallery?.apartmentGallery?.length === 0)
  ) {
    return null
  }
  const activeGallery = imageGallery?.apartmentGallery?.length
    ? imageGallery.apartmentGallery
    : gallery
  return (
    <StyledMain>
      <StyledGalleryDiv>
        <StyledRightDiv>
          <Image
            src={getUmbracoMediaUrl(imageGallery?.apartmentThumbnail || gallery[0])}
            alt="poster 1"
            layout="fill"
            style={{ objectFit: "cover" }}
          />
          <StyledIconsSwapper>
            <StyledExpandIcon>
              <IconButton onClick={() => setIsOpen(true)}>
                <GalleryIcon />
              </IconButton>
            </StyledExpandIcon>
          </StyledIconsSwapper>
          {apartment.url && (
            <StyledVirtualDiv>
              <Link href={apartment.url} target="_blank">
                <NewText
                  component={"p"}
                  desktopFontSize={16}
                  desktopLineHeight={"23px"}
                  mobileFontSize={14}
                  mobileLineHeight={"20px"}
                  desktopFontWeight={400}
                  color={sharedColors.white}
                >
                  {t("virtualTour")}
                </NewText>
              </Link>
            </StyledVirtualDiv>
          )}
        </StyledRightDiv>
        <Desktop>
          <StyledLeftDiv>
            {activeGallery?.[1] && (
              <SytledImageWrapper>
                <Image
                  src={getUmbracoMediaUrl(activeGallery?.[1])}
                  alt="poster 2"
                  layout="fill"
                  style={{ objectFit: "cover" }}
                />
              </SytledImageWrapper>
            )}

            {activeGallery?.[2] && (
              <SytledImageWrapper>
                <Image
                  src={getUmbracoMediaUrl(activeGallery?.[2])}
                  alt="poster 2"
                  layout="fill"
                  style={{ objectFit: "cover" }}
                />
              </SytledImageWrapper>
            )}
          </StyledLeftDiv>
        </Desktop>
      </StyledGalleryDiv>
      {isOpen && (
        <StyledGalleryPopup>
          <StyledContainer>
            <StyledCloseDiv>
              <IconButton onClick={() => setIsOpen(false)}>
                <XIcon />
              </IconButton>
            </StyledCloseDiv>
            <StyledImagesDiv>
              <StyledMainImage heightMargin={350}>
                <Image
                  src={getUmbracoMediaUrl(activeGallery?.[activeIndex])}
                  layout="fill"
                  style={{ objectFit: "cover" }}
                  alt="poster"
                />
              </StyledMainImage>
              <StyledThumbImages>
                <StyledIconButton
                  onClick={() => updateSwiperSlide(activeIndex - 1)}
                  disabled={activeIndex === 0}
                >
                  {locale === "en" ? <LeftArrow /> : <RightArrow />}
                </StyledIconButton>
                <Swiper
                  onSwiper={(swiper) => (swiperRef.current = swiper)} // Capture the Swiper instance
                  navigation={{
                    nextEl: `.arrow-left`,
                    prevEl: `.arrow-right`,
                  }}
                  hashNavigation
                  modules={[Navigation]}
                  slidesPerView={2.2}
                  breakpoints={{
                    960: {
                      slidesPerView: 4.5,
                    },
                  }}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  spaceBetween={"16px"}
                >
                  {activeGallery.map((image, index) => (
                    <SwiperSlide
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => updateSwiperSlide(index)}
                    >
                      <StyledSlideDiv $active={index === activeIndex}>
                        <Image
                          key={index}
                          src={getUmbracoMediaUrl(image)}
                          layout="fill"
                          style={{ objectFit: "cover" }}
                          alt={`poster ${index}`}
                        />
                      </StyledSlideDiv>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <StyledIconButton
                  onClick={() => updateSwiperSlide(activeIndex + 1)}
                  disabled={activeIndex === activeGallery.length - 1}
                >
                  {locale === "en" ? <RightArrow /> : <LeftArrow />}
                </StyledIconButton>
              </StyledThumbImages>
            </StyledImagesDiv>
          </StyledContainer>
        </StyledGalleryPopup>
      )}
    </StyledMain>
  )
}

export default ApartmentGallery
