import styled from "@emotion/styled"
import { Button, Tab, Tabs } from "@mui/material"
import { useTranslations } from "next-intl"
import { useRef, useState } from "react"
import { useOutsideClick } from "utils"
import { choosenProject, submenuClick } from "utils/gtm"
import { ApartmentUmbraco } from "utils/projects"
import ContactForm from "../../Form"
import { ProjectsMessages } from "../../Projects"
import { Backdrop } from "../../StandardProject"
import AccordionInnerDetails from "./AccordionInnerDetails"
import { a11yProps } from "./Apartments"

const StyledInner = styled.div`
  gap: 24px;
  padding: 24px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px;
  }
  background-color: #f7f7f7;
`
const TabsContainer = styled(Tabs)`
  .MuiTabs-flexContainer {
    display: flex;
    gap: 24px;
  }
`

const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const StyledTab = styled(Tab)<{ active: boolean }>`
  min-height: 0;
  padding: 0;
  min-width: 0;
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 20px;
  color: ${({ active }) => (active ? "black" : "#6F6F6F")};
  font-weight: ${({ active }) => (active ? "600" : "400")};
  border-bottom: ${({ active, theme }) => (active ? `2px solid ${theme.colors.propBlue}` : "")};
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 22px;
  }
`

const StickyFormWrap = styled.div`
  width: 100%;
  position: fixed;

  left: 0px;
  z-index: 10000;
  max-height: calc(100% - 40px);
  overflow: auto;
  ${(props) => props.theme.breakpoints.down("sm")} {
    bottom: 0px;
    height: 100%;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledButton = styled(Button)`
  align-self: end;
  font-size: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
  }
`
const StyledActive = styled.div`
  flex-grow: 1;
`

type TabProps = {
  value: number
  index: number
  apartment: ApartmentUmbraco
}

function ActiveTab({ index, value, apartment }: TabProps) {
  const getType = (): "plan" | "specs" | "docs" => {
    switch (index) {
      case 0:
        return "plan"
      case 1:
        return "specs"
      case 2:
        return "docs"
      default:
        return "plan"
    }
  }
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <AccordionInnerDetails apartment={apartment} type={getType()} />}
    </div>
  )
}

type Props = {
  apartment: ApartmentUmbraco
  formSubjects: Record<string, string>
  messages: ProjectsMessages
  bmbyId?: number
  status?: any
  leadFormBMBYId?: any
}

function AccordionInner({ apartment, formSubjects, messages, status, leadFormBMBYId }: Props) {
  const t = useTranslations("AccordionDetails")
  const [value, setValue] = useState(0)
  const [isBackdropVisible, setIsBackdropVisible] = useState(false)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const formRef = useRef(null)
  const handleFormClose = () => {
    setIsFormVisible(false)
    setIsBackdropVisible(false)
  }
  const handleFormOpen = () => {
    choosenProject("מעוניינ/ת בדירה", apartment?.propertyName)
    submenuClick("מעוניינ/ת בדירה", apartment?.propertyName)
    setIsFormVisible(true)
    setIsBackdropVisible(true)
  }
  useOutsideClick([formRef], handleFormClose)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    submenuClick(
      newValue === 0 ? "תוכנית דירה" : newValue === 1 ? "מפרט דירה" : "מסמכים וחוזים",
      apartment?.propertyName,
    )
    setValue(newValue)
  }
  const formData = {
    price: apartment.price,
    currency: apartment.priceCurrency,
    area: apartment.areaInSquareMeters,
    floor: apartment.floor,
    rooms: apartment.numberOfRooms,
  }
  return (
    <>
      <StyledInner>
        <TabsContent>
          <TabsContainer value={value} onChange={handleChange} aria-label="property types">
            <StyledTab active={0 === value} key={0} label={t("floorPlan")} {...a11yProps(0)} />
            <StyledTab
              active={1 === value}
              key={1}
              label={t("apartmentSpecif")}
              {...a11yProps(1)}
            />
            <StyledTab active={2 === value} key={2} label={t("agreementDocs")} {...a11yProps(2)} />
          </TabsContainer>
        </TabsContent>
        <StyledActive>
          {Array.from([0, 1, 2]).map((el, i) => (
            <ActiveTab key={i} index={i} value={value} apartment={apartment} />
          ))}
        </StyledActive>
        <StyledButton variant="outlined" onClick={() => handleFormOpen()}>
          {t("lookForApt")}
        </StyledButton>
      </StyledInner>
      {isBackdropVisible && <Backdrop z={120} />}
      {isFormVisible ? (
        <StickyFormWrap ref={formRef}>
          <ContactForm
            formData={formData}
            messages={messages}
            formSubjects={formSubjects}
            onClose={handleFormClose}
            bmbyId={apartment.leadFormBMBYId || leadFormBMBYId}
            status={status}
            emailForLead={apartment.emailForLead}
          />
        </StickyFormWrap>
      ) : null}
    </>
  )
}

export default AccordionInner
