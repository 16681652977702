import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { useLocale, useTranslations } from "next-intl"
import Image from "next/image"
import { useState } from "react"
import { Desktop, DownloadIcon, GalleryIcon, Mobile, NewText, XIcon } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment, getUmbracoMediaUrl, handleDownload } from "utils"
import {
  StyledCloseDiv,
  StyledContainer,
  StyledExpandIcon,
  StyledGalleryPopup,
  StyledIconsSwapper,
  StyledImagesDiv,
  StyledMainImage,
} from "./ApartmentGallery"
import MiniMap from "./MiniMap"

const StyledPlanDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 24px;
  }
`

const StyledBluePrintDiv = styled.div`
  height: 280px;
  background-color: white;
  border-radius: 16px;
  width: 100%;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 480px;
    width: 65%;
  }
`

const StyledMoreInfo = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 35%;
  }
`

const StyledValueDiv = styled.div`
  min-height: 39px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f7f7f7;
  &:last-child {
    border-bottom: none;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 42px;
  }
`

const MapContainer = styled.div`
  height: 100%;
`
type Props = {
  apartment: Apartment
  projectPage?: boolean
}

function ApartmentPlan({ apartment, projectPage }: Props) {
  const t = useTranslations("AccordionDetails")
  const [open, setOpen] = useState(false)

  const locale = useLocale()

  return (
    <StyledPlanDiv>
      <StyledBluePrintDiv>
        {apartment.blueprintOfTheApartment && (
          <>
            <Image
              src={getUmbracoMediaUrl(apartment.blueprintOfTheApartment)}
              alt="poster 1"
              layout="fill"
              style={{ objectFit: "contain" }}
            />
            <StyledIconsSwapper>
              <StyledExpandIcon>
                <IconButton onClick={() => setOpen(true)}>
                  <GalleryIcon />
                </IconButton>
              </StyledExpandIcon>
              <Desktop>
                <StyledExpandIcon
                  role="button"
                  locale={locale}
                  customTooltip={true}
                  onClick={() =>
                    handleDownload({
                      fileUrl: getUmbracoMediaUrl(apartment.blueprintOfTheApartment),
                      fileName: apartment.propertyTitle || "",
                    })
                  }
                >
                  <IconButton>
                    <DownloadIcon />
                  </IconButton>
                  <NewText
                    component={"p"}
                    desktopFontSize={12}
                    desktopLineHeight={"14.52px"}
                    desktopFontWeight={400}
                    mobileFontSize={12}
                    mobileLineHeight={"14.52px"}
                    color={sharedColors.black}
                  >
                    {locale === "he" ? "להורדה" : "Download"}
                  </NewText>
                </StyledExpandIcon>
              </Desktop>
            </StyledIconsSwapper>
            <Mobile>
              <StyledIconsSwapper mobileLeft="unset" mobileRight="16px">
                <StyledExpandIcon
                  role="button"
                  locale={locale}
                  direction="row-reverse"
                  customTooltip={true}
                  onClick={() =>
                    handleDownload({
                      fileUrl: getUmbracoMediaUrl(apartment.blueprintOfTheApartment),
                      fileName: apartment.propertyTitle || "",
                    })
                  }
                >
                  <IconButton>
                    <DownloadIcon />
                  </IconButton>
                  <NewText
                    component={"p"}
                    desktopFontSize={12}
                    desktopLineHeight={"14.52px"}
                    desktopFontWeight={400}
                    mobileFontSize={12}
                    mobileLineHeight={"14.52px"}
                    color={sharedColors.black}
                  >
                    {locale === "he" ? "להורדה" : "Download"}
                  </NewText>
                </StyledExpandIcon>
              </StyledIconsSwapper>
            </Mobile>
            {open && (
              <StyledGalleryPopup>
                <StyledContainer>
                  <StyledCloseDiv>
                    <IconButton onClick={() => setOpen(false)}>
                      <XIcon />
                    </IconButton>
                  </StyledCloseDiv>
                  <StyledIconsSwapper
                    top={"86px"}
                    right={"0"}
                    left={"unset"}
                    mobileTop="86px"
                    mobileRight="16px"
                    mobileLeft="unset"
                  >
                    <StyledExpandIcon
                      role="button"
                      direction={"row-reverse"}
                      locale={locale}
                      customTooltip={true}
                      onClick={() =>
                        handleDownload({
                          fileUrl: getUmbracoMediaUrl(apartment.blueprintOfTheApartment),
                          fileName: apartment.propertyTitle || "",
                        })
                      }
                    >
                      <IconButton style={{ width: 40, height: 40 }}>
                        <DownloadIcon />
                      </IconButton>
                      <NewText
                        component={"p"}
                        desktopFontSize={12}
                        desktopLineHeight={"14.52px"}
                        desktopFontWeight={400}
                        mobileFontSize={12}
                        mobileLineHeight={"14.52px"}
                        color={sharedColors.black}
                      >
                        {locale === "he" ? "להורדה" : "Download"}
                      </NewText>
                    </StyledExpandIcon>
                  </StyledIconsSwapper>
                  <StyledImagesDiv>
                    <StyledMainImage heightMargin={200}>
                      <Image
                        src={getUmbracoMediaUrl(apartment.blueprintOfTheApartment)}
                        layout="fill"
                        style={{ objectFit: "cover" }}
                        alt="poster"
                      />
                    </StyledMainImage>
                  </StyledImagesDiv>
                </StyledContainer>
              </StyledGalleryPopup>
            )}
          </>
        )}
      </StyledBluePrintDiv>
      <RightSide>
        <StyledMoreInfo>
          <NewText
            component={"p"}
            desktopFontSize={16}
            desktopLineHeight={"23px"}
            desktopFontWeight={600}
            mobileFontSize={14}
            mobileLineHeight={"20px"}
            color={sharedColors.black}
          >
            {t("moreInformation")}
          </NewText>
          <StyledValueDiv>
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={sharedColors.black}
            >
              {t("building")}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={sharedColors.black}
            >
              {apartment.buildingTitle}
            </NewText>
          </StyledValueDiv>
          <StyledValueDiv>
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={sharedColors.black}
            >
              {t("apartmentNumber")}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={sharedColors.black}
            >
              {apartment.apartmentNumber}
            </NewText>
          </StyledValueDiv>
          <StyledValueDiv>
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={sharedColors.black}
            >
              {t("direction")}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={sharedColors.black}
            >
              {apartment.orientation}
            </NewText>
          </StyledValueDiv>
          <StyledValueDiv>
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={sharedColors.black}
            >
              {t("boardfee")}
            </NewText>
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={sharedColors.black}
            >
              {apartment.houseCommitee} ₪
            </NewText>
          </StyledValueDiv>
        </StyledMoreInfo>
        {!projectPage && (
          <MapContainer>
            <MiniMap locale={locale} address={apartment.location} />
          </MapContainer>
        )}
      </RightSide>
    </StyledPlanDiv>
  )
}

export default ApartmentPlan
